<script setup>
import { useApp } from "~/composables/useApp";
import { useCategories } from "~/composables/useCategories";
import { useBanners } from "~/composables/useBanners";

const { categoriesColl } = useCategories();
const { getBannersByCategory } = useBanners();
const { bibles, appVars } = useApp();

const categoryPreviewDialog = ref(false);
const bannerPreviewDialog = ref(false);
const selectedBanner = ref(null);
const selectedCategory = ref(null);

const selectedTab = ref("bible");

function openBanner(bannerModel) {
  selectedBanner.value = bannerModel;
  bannerPreviewDialog.value = true;
}
</script>

<template>
  <div class="gallery-wrapper">
    <div class="app-header pl-2 pr-2">
      <v-btn @click="appVars.appMenu = true" icon="sm" class="icon-size">
        <div class="menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </v-btn>

      <div class="nav-title">{{ transl("Gallery") }}</div>
      <v-gallery-menu></v-gallery-menu>
    </div>

    <div class="tabs-labels-wrapper">
      <v-tabs-labels v-model="selectedTab" class="tabs-el mt-7 mb-2">
        <v-tab-label value="bible" class="tab-el pb-2 ml-3">
          <span>{{ transl("Biblical") }}</span>
        </v-tab-label>
        <v-tab-label value="greetings" class="tab-el pb-2 mr-5 ml-5">
          <span>{{ transl("Greetings") }}</span>
        </v-tab-label>
        <v-tab-label value="thematic" class="tab-el pb-2">
          <span>{{ transl("Thematic") }}</span>
        </v-tab-label>
      </v-tabs-labels>
    </div>

    <v-tabs v-model="selectedTab" class="tabs-labels-el width-100">
      <v-tab value="bible">
        <div class="banner-row mx-3 mt-5">
          <template v-for="category in categoriesColl" :key="category._id + 'bible'">
            <div
              v-if="category.topcat == 'bible'"
              class="banner-card"
              @click="
                selectedCategory = category;
                categoryPreviewDialog = true;
              "
            >
              <div class="image">
                <img :src="category.image" alt="" />
              </div>
              <div class="label">{{ transl(category.label) }}</div>
            </div>
          </template>
        </div>
      </v-tab>
      <v-tab value="greetings">
        <div class="banner-row mx-3 mt-5">
          <template v-for="category in categoriesColl" :key="category._id">
            <div
              v-if="category.topcat == 'greetings'"
              class="banner-card"
              @click="
                selectedCategory = category;
                categoryPreviewDialog = true;
              "
            >
              <div class="image">
                <img :src="category.image" alt="" />
              </div>
              <div class="label">{{ transl(category.label) }}</div>
            </div>
          </template>
        </div>
      </v-tab>
      <v-tab value="thematic">
        <div class="banner-row mx-3 mt-5">
          <template v-for="category in categoriesColl" :key="category._id">
            <div
              v-if="category.topcat == 'thematic'"
              class="banner-card"
              @click="
                selectedCategory = category;
                categoryPreviewDialog = true;
              "
            >
              <div class="image">
                <img :src="category.image" alt="" />
              </div>
              <div class="label">{{ transl(category.label) }}</div>
            </div>
          </template>
        </div></v-tab
      >
    </v-tabs>
  </div>

  <!-- <v-btn class="close-btn" @click="goPrev()">
          <v-svg
            src="/icons/arrow-sm-left-svgrepo-com.svg"
            :options="{ width: '30px' }"
          ></v-svg>
        </v-btn> -->

  <v-category-preview-dialog
    v-model="categoryPreviewDialog"
    :categoryModel="selectedCategory"
    @select="(el) => openBanner(el)"
  ></v-category-preview-dialog>

  <v-image-preview-dialog
    v-model="bannerPreviewDialog"
    :imageModel="selectedBanner"
  ></v-image-preview-dialog>
</template>
<style scoped>
.gallery-wrapper {
  /* height: calc(100vh - 115px); */
  overflow-y: scroll;
}
.banner-row {
  display: grid;
  gap: 10px 10px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 100px;
}
@media (max-width: 600px) {
  .banner-row {
    grid-template-columns: 1fr;
  }
}
.banner-card {
  cursor: pointer;
  text-align: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.banner-card .image {
  aspect-ratio: 100/19;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.banner-card .image:before {
  content: "";
  position: absolute;
  background-color: rgba(41, 41, 55, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
}
.banner-card .banner-card img {
  aspect-ratio: 1;
  object-fit: cover;
}

.banner-card img {
  transition: scale 0.6s;
}
.banner-card:hover img {
  scale: 1.05;
}
.label {
  font-size: 18px;
  min-height: 15px;
  color: #fff;
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.nav-title {
  color: #000;
  font-weight: bold;
  font-size: 21px;
}
/* @media (max-width: 480px) {
  .banner-row {
    gap: 7px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 30px);
  }
  .label {
    font-size: 17px;
    margin-top: 0;
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .banner-card {
    position: relative;
  }
  .banner-card .label {
    position: absolute;
    top: 0;
    color: #fff;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-wrap: nowrap;
  }
  .banner-card .image {
    aspect-ratio: auto;
  }
  .banner-card {
    height: 100px;
  }
} */
@media (max-width: 380px) {
  .label {
    font-size: 13px;
  }
}
</style>

<style scoped>
.app-header {
  width: 100%;
  height: var(--header-height);
  background-color: #fff;
  display: flex;
  align-items: center;
  max-width: var(--width-mobile);
  font-size: 24pt;

  max-width: var(--width-mobile);
  box-sizing: border-box;
  position: sticky;
  top: 0;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 5;
}

.icon {
  width: 40px;
}

.logo-box {
  display: flex;
}

.icon-size {
  color: #292937;
  width: 40px;
  height: 40px;
}

.icon-size.fix {
  width: 30px;
  height: 100%;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23px;
  height: 15px;
}

.menu span {
  background: #292937;
  height: 3px;
  border-radius: 4px;
  width: 100%;
}

.menu span:nth-child(2n + 1) {
  width: 70%;
  transition: all 0.3s ease;
}

.menu span:nth-child(1) {
  margin-right: auto;
}

.menu span:nth-child(3) {
  margin-left: auto;
}

.menu:hover span:nth-child(2n + 1) {
  width: 100%;
}

.background {
  background-color: #292937;
  border-radius: 8px;
  min-width: 31px;
  min-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background img {
  width: 11px;
  height: 13px;
}

.logo {
  width: 81px;
  height: 34px;
}

.gallery-thumbnail {
  margin: 0px;
}
.tabs-labels-wrapper {
  width: 350px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.tabs {
  gap: 20px;
}
/* .tab-el {
  margin-right: 50px;
}
.tab-el:nth-child(1) {
  margin-left: 50px;
} */
/* .v-tab-label {
  width: 80px;
  min-width: 80px;
  text-align: center;
} */

/* @media (max-width: 420px) {
  .tabs-labels-wrapper {
    width: 320px;
  }
  .tab-el {
    margin-right: 25px;
  }
  .tab-el:nth-child(1) {
    margin-left: 25px;
  }
} */
/* div :deep() .tabs {
  justify-content: center !important;
} */
</style>
